const baseColumn = vm => {
  return [
    {
      keyId: 1,
      title: '项目名称',
      field: 'name',
      width: null,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      isSearchShow: true,
      selectOptions: [{
        value:'',
        label:'全部项目'
      }],
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 4,
      title: '工地状态',
      field: 'status',
      width: null,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      isSearchShow: false,
      showField: true,
      enum: true,
      selectOptions: [
        {
          label: '已开工',
          value: 'OPEN'
        },
        {
          label: '未开工',
          value: 'NOT_OPEN'
        }
      ],
      fieldEnumValueList: {
        OPEN: '已开工',
        NOT_OPEN: '未开工'
      },
      fieldOperateTag: {
        OPEN: 'success',
        NOT_OPEN: 'warning'
      },
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 2,
      title: '历史总工单',
      field: 'orderCount',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 3,
      title: '创建时间',
      field: 'created',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}
// 操作按钮配置项
const operateList = vm => {
  return []
}

// 搜索字段配置项
const searchFields = vm => {
  return [
    {
      keyId: 1,
      title: '项目名称',
      field: 'name',
      width: 160,
      searchType: 'select',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      selectOptions: [{
        value:'',
        label:'全部项目'
      }],
      renderType: 'status',
      copy: true, //是否拥有复制功能
      columnType: null, // 筛选自定义
      filterable: true // 是否可筛选
    }
    // {
    //   keyId: 2,
    //   title: '工地状态',
    //   field: 'status',
    //   width: 160,
    //   searchType: 'select',
    //   searchValue: '', //搜索绑定值
    //   renderType: 'status', //achnor,status,option
    //   sortable: true, //是否排序
    //   textColor: '#e6a23c',
    //   copy: true, //是否拥有复制功能
    //   selectOptions: [
    //     {
    //       label: '已开工',
    //       value: 'OPEN'
    //     },
    //     {
    //       label: '未开工',
    //       value: 'NOT_OPEN'
    //     }
    //   ],
    // },
    // {
    //   keyId: 3,
    //   title: '创建时间',
    //   field: 'created',
    //   width: 160,
    //   searchType: 'daterange',
    //   searchValue: '', //搜索绑定值
    //   sortable: false, //是否排序
    //   copy: true //是否拥有复制功能
    // }
  ]
}

// 表格列配置
const tableFields = vm => {
  return [
    {
      keyId: 1,
      title: '项目名称',
      field: 'name',
      width: null,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      isSearchShow: true,
      selectOptions: [{
        value:'',
        label:'全部项目'
      }],
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 4,
      title: '工地状态',
      field: 'status',
      width: null,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      isSearchShow: false,
      showField: true,
      enum: true,
      selectOptions: [
        {
          label: '已开工',
          value: 'OPEN'
        },
        {
          label: '未开工',
          value: 'NOT_OPEN'
        }
      ],
      fieldEnumValueList: {
        OPEN: '已开工',
        NOT_OPEN: '未开工'
      },
      fieldOperateTag: {
        OPEN: 'success',
        NOT_OPEN: 'warning'
      },
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 2,
      title: '历史总工单',
      field: 'orderCount',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 3,
      title: '创建时间',
      field: 'created',
      width: null,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      isSearchShow: false,
      selectOptions: null,
      showField: true,
      enum: false,
      fieldEnumValueList: null,
      fieldOperateTag: null,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}

export default {
  operateList,
  searchFields,
  tableFields,
  baseColumn
}

<template>
  <div style="height: 100%; width: 100%; scroll-y: hidden">
    <search-table
      ref="searchTable"
      labelWidth="70px"
      :loading="listLoading"
      :searchFields="searchFields"
      :autoBtnList="autoBtnList"
      :colSize="colSize"
      :total="total"
      :tableData="dataList"
      :tableColumns="tableColumns"
      :baseColumns="baseColumns"
      :sortFields="sortFields"
      :checkboxColumn="true"
      :hasPagination="true"
      :showFooter="true"
      :showSetting="false"
      :showColumnSearch="false"
      :showSortable="true"
      :tableSummary="tableSummary"
      :proxyParams="proxyParams"
      :optionColumn="optionColumn"
      :selectTableSummary="selectTableSummary"
      @saveTableField="saveTableField"
      @saveSearchField="saveSearchField"
      @saveSortField="saveSortField"
      @getTableData="queryList"
      @dataStt="dataStt"
      @orderManage="orderManage"
      @personalMange="personalMange"
      @configManage="configManage"
    >
    </search-table>
  </div>
</template>
<script>
import { queryList } from '@/api/project'
import { projectList } from '@/api/stt'
import SearchTable from '@/components/LcSearchTable'
import config from '@/const/project/index.js'
import { formatDate } from '@/utils/time'
import { columnSetting } from '@/api/common'
import column from '@/mixins/column.js'
export default {
  components: {
    SearchTable
  },
  mixins: [column],

  data: function () {
    return {
      total: 0,
      summary: {},
      dataList: [],
      listLoading: false,
      // 查询条件
      formParams: {},
      baseColumn: [], //接口请求的表格配置
      baseColumns: [],
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 3, //用户设置展示列比例
      bossProList: [],
      selectTableSummary: config.selectTableSummary, // 需要选中合计的字段
      sortFields: [], // 用户自定义的排序设置
      optionColumn: {
        title: '操作',
        width: 270,
        fixed: 'right',
        btnList: [
          {
            title: '数据统计',
            fn: 'dataStt'
          },
          {
            title: '工单管理',
            fn: 'orderManage'
          },
          {
            title: '人员管理',
            fn: 'personalMange'
          },
          {
            title: '配置管理',
            fn: 'configManage'
          }
        ]
      },
      path: '/system/tenant/findByPage' // 更新字段名显示接口路径
    }
  },
  created() {
    this.searchFields = config.searchFields(this)
    this.baseColumns = config.tableFields(this)
    this.tableColumns = config.tableFields(this)
  },
  computed: {
    // 操作按钮
    autoBtnList() {
      return config.operateList(this)
    }
  },
  activated() {
    this.getProjectList()
    this.queryList()
  },
  methods: {
    // 获取该老板所有的工地
    getProjectList() {
      projectList().then(res => {
        let arr = [{ value: '', label: '全部项目' }]
        res.data.forEach(item => {
          arr.push({ value: item.name, label: item.name })
        })
        this.bossProList = arr
      })
    },
    // 数据统计
    dataStt(row) {
      this.$router.push({ path: '/project/statistics', query: { id: row.id, name: row.name } })
    },
    // 工单管理
    orderManage(row) {
      this.$router.push({ path: '/project/order', query: { id: row.id, name: row.name } })
    },
    // 人员管理
    personalMange(row) {
      this.$router.push({ path: '/project/personal', query: { id: row.id, name: row.name } })
    },
    // 配置管理
    configManage(row) {
      this.$router.push({ path: '/project/config', query: { id: row.id, name: row.name } })
    },
    // 查询
    async queryList(params) {
      if (params) {
        this.formParams = params
      } else {
        params = this.formParams
      }
      params['role'] = this.$store.getters.userType
      this.listLoading = true
      this.baseColumns = config.baseColumn(this)
      const res = await queryList({ ...params })

      if (res.code === 200) {
        this.dataList = res.data.records
        this.total = res.data.total * 1
        // this.tableSummary = res.data.summary
        // this.baseColumns = res.data.tableSettingFields || config.baseColumn(this)
        // this.baseColumns =  config.baseColumn(this)
        // this.path = res.data.path || this.path
        // this.colSize = res.data.colSize
        // this.initConfig()
      }
      this.searchFields.forEach(item => {
        if (item.field === 'name') {
          item.selectOptions = this.bossProList
        }
      })
      this.listLoading = false
    },
    initConfig() {
      this.tableColumns = this.baseColumns.filter(v => v.showField)
      this.searchFields = this.baseColumns.filter(v => v.isSearchShow)
      this.sortFields = this.tableColumns
        .filter(v => v.sortable)
        .reduce((prev, item) => {
          // if(item.sortList) prev = prev.concat(item.sortList)
          prev.push({
            title: `${item.title}升序`,
            field: `${item.field}-asc`
          })
          prev.push({
            title: `${item.title}降序`,
            field: `${item.field}-desc`
          })
          return prev
        }, [])
    },
    // 时间格式化
    dateFormat(data) {
      return formatDate(data)
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      this.tableColumns = fields
      try {
        const res = await columnSetting({
          path: this.path,
          // searchFields: this.searchFields,
          tableFields: fields
        })
        this.getTableData()
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      // console.log(fields, colSize)
      this.colSize = colSize
      this.searchFields = fields
      try {
        const res = await columnSetting({
          path: this.path,
          searchFields: fields,
          // tableFields: this.tableColumns,
          colSize
        })
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改排序字段配置
    async saveSortField(fields) {
      this.sortFields = fields
    },
    // 处理数据
    proxyParams(params) {
      console.log(params)
    },

    //获取表格头配置
    async getTableField() {},
    //获取搜索字段配置
    async getSearchField() {}
  }
}
</script>
